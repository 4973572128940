<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Item No."
          v-model="itemRecord.ItemCode"
          color="primary"
          :append-icon="'mdi-airballoon'"
          @click:append="openItemModal"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Item Description" v-model="itemRecord.Dscription" readonly></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Bar Code" v-model="itemRecord.CodeBars"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Serial No." v-model="itemRecord.SerialNum" readonly></v-text-field>
      </v-col>

      <!--  -->
      <v-col cols="2">
        <v-text-field label="Quantity" v-model="Quantity" type="number"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Delivered Qty" v-model="itemRecord.DelivrdQty" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-radio-group v-model="itemRecord.UseBaseUn" row>
          <template v-slot:label>
            <div>
              <strong>Inventory UoM</strong>
            </div>
          </template>
          <v-radio label="No" value="N"></v-radio>
          <v-radio label="Yes" value="Y"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="2">
        <v-text-field label="No of Packages" v-model="itemRecord.PackQty" type="number"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Unit Price" v-model="PriceBefDi" type="number"></v-text-field>
      </v-col>
      <!--  -->

      <!--  -->
      <v-col cols="2">
        <v-text-field label="Discount %" v-model="DiscPrcnt" type="number"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Rate" v-model="itemRecord.Rate" type="number"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Price After Discount" v-model="Price" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="TaxCode"
          :items="taxes"
          item-text="name"
          item-value="id"
          label="Tax Code"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Gross Price After Disc" v-model="PriceAfVAT" type="number"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Total (LC)" v-model="LineTotal" type="number"></v-text-field>
      </v-col>
      <!--  -->

      <!--  -->
      <v-col cols="2">
        <v-text-field label="Warehouse Code" v-model="itemRecord.WhsCode"></v-text-field>
      </v-col>
      <v-col cols="2">
        <DatePicker @date="setShipDate" :myDate="itemRecord.ShipDate" :title="`Del Date`"></DatePicker>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="itemRecord.SlpCode"
          :items="employees"
          item-text="SlpName"
          item-value="id"
          label="Sales Employee"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Comm %" v-model="itemRecord.Commission" type="number"></v-text-field>
      </v-col>
      <!-- <v-col cols="2">
              <v-text-field label="G/L Account" v-model="itemRecord.p"></v-text-field>
      </v-col>-->
      <!--  -->

      <!--  -->
      <v-col cols="2">
        <v-text-field label="Open Qty" v-model="Quantity" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="In Stock" v-model="itemRecord.OnHand" filled readonly></v-text-field>
      </v-col>
      <!--  -->

      <!--  -->
      <v-col cols="2">
        <v-text-field label="Commited" v-model="itemRecord.IsCommited" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Ordered" v-model="itemRecord.OnOrder" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Bom Type" v-model="itemRecord.TreeType" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Row Status" v-model="itemRecord.LineStatus" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Target Type" v-model="itemRecord.TargetType" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Target Key" v-model="itemRecord.TargetEntry" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Base Type" v-model="itemRecord.BaseType" filled readonly></v-text-field>
      </v-col>
      <!--  -->

      <!--  -->
      <v-col cols="2">
        <v-text-field label="Base Ref" v-model="itemRecord.BaseRef" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Base Key" v-model="itemRecord.BaseEntry" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Base Row" v-model="itemRecord.BaseLine" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Price Source" v-model="itemRecord.SpecsPrice" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Tax Amount (LC)" v-model="VatSum" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Gross Profit (LC)" v-model="GrssProfit" filled readonly></v-text-field>
      </v-col>
      <!--  -->

      <!--  -->
      <v-col cols="2">
        <v-text-field label="Procurement Doc" v-model="itemRecord.PoTrgNum" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-checkbox v-model="BackOrdr" :label="`Partial Delivery`" true-value="Y" false-value="N"></v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Free Text" v-model="itemRecord.FreeTxt"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Pick Status" v-model="itemRecord.PickStatus" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          @change="getPrices(UomCode)"
          v-model="UomCode"
          :items="uoms"
          item-text="UoMName"
          item-value="UomEntry"
          label="Uom Code"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-text-field label="UoM Name" v-model="itemRecord.unitMsr" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Items Per Unit" v-model="itemRecord.NumPerMsr" filled readonly></v-text-field>
      </v-col>
      <!--  -->

      <!--  -->
      <v-col cols="2">
        <v-text-field label="Item Details" v-model="itemRecord.Text"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Gross Total (LC)" v-model="GTotal" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Qty (Inventory UoM)" v-model="Quantity" type="number"></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Open Inv Qty" v-model="Quantity" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="UoM Group" v-model="itemRecord.UgpCode" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Blanket Agreement No." v-model="itemRecord.AgrNo" filled readonly></v-text-field>
      </v-col>
      <!--  -->

      <!--  -->
      <v-col cols="2">
        <v-checkbox
          v-model="LinePoPrss"
          :label="`Allow Procmnt Doc`"
          true-value="Y"
          false-value="N"
        ></v-checkbox>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Qty in Whse" v-model="itemRecord.OnHand" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Committed Qty in Whse" v-model="itemRecord.IsCommited" filled readonly></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field label="Ordered Qty in Whse" v-model="itemRecord.OnOrder" filled readonly></v-text-field>
      </v-col>
      <!--  -->
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="accent" @click="addItem">
          <v-icon left>mdi-plus</v-icon>Add Item
        </v-btn>
      </v-col>
    </v-row>

    <!-- end of item dialog -->

    <!-- one item selection modal -->
    <v-dialog v-model="itemDialog" width="950">
      <v-toolbar dark color="primary">
        <v-toolbar-title>List of Items</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="itemDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="searchItem"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  @item-selected="clickedItem"
                  :items-per-page="itemsPerPage"
                  v-model="selectedItems"
                  :headers="itemMasterHeaders"
                  :items="itemMasterData"
                  :single-select="singleSelect"
                  item-key="id"
                  show-select
                  class="elevation-1"
                  :search="searchItem"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item modal -->

    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
    <!-- end of snakbar -->
  </div>
</template>

<script>
export default {
  props: {
    selectedTenant: {
      type: Object
    },
    initial: {
      type: Object,
      required: true
    }
  },
  computed: {
    Price() {
      let initialPrice = null;
      initialPrice = parseFloat(this.PriceBefDi);
      const discount = parseFloat(this.DiscPrcnt);
      let newPrice = initialPrice;
      if (discount > 0) {
        const discountPrice = (discount / 100) * initialPrice;
        newPrice = initialPrice - discountPrice;
        return newPrice;
      } else {
        return initialPrice;
      }
    },
    LineTotal() {
      let initialPrice = null;
      // get quantity
      const quantity = parseFloat(this.Quantity);
      initialPrice = parseFloat(this.PriceBefDi);
      const discount = parseFloat(this.DiscPrcnt);
      let newPrice = initialPrice;
      if (discount > 0) {
        const discountPrice = (discount / 100) * initialPrice;
        newPrice = initialPrice - discountPrice;
        return newPrice * quantity;
      } else {
        return initialPrice * quantity;
      }
    },
    GrssProfit() {
      let initialPrice = null;
      // get quantity
      const quantity = parseFloat(this.Quantity);
      initialPrice = parseFloat(this.PriceBefDi);
      const discount = parseFloat(this.DiscPrcnt);
      let newPrice = initialPrice;
      if (discount > 0) {
        const discountPrice = (discount / 100) * initialPrice;
        newPrice = initialPrice - discountPrice;
        return newPrice * quantity;
      } else {
        return initialPrice * quantity;
      }
    },
    PriceAfVAT() {
      const id = parseInt(this.TaxCode);
      if (id) {
        const tax = this.taxes.find(record => {
          return record.id === id;
        });
        if (tax) {
          const taxRate = parseFloat(tax.rate);
          const amount = this.Price;
          if (taxRate > 0) {
            const taxAmount = (taxRate / 100) * amount;
            return amount + taxAmount;
          } else {
            return amount;
          }
        } else {
          return this.price;
        }
      } else {
        return 0;
      }
    },
    VatSum() {
      const id = parseInt(this.TaxCode);
      if (id) {
        const tax = this.taxes.find(record => {
          return record.id === id;
        });

        if (tax) {
          const taxRate = parseFloat(tax.rate);
          // check tax rate
          if (taxRate > 0) {
            const taxAmount = (taxRate / 100) * this.PriceBefDi;
            let totalTax = 0;
            let discountAmount = 0;
            totalTax = taxAmount * this.Quantity;
            const discount = parseFloat(this.DiscPrcnt);
            if (discount > 0) {
              discountAmount = (discount / 100) * totalTax;
              return totalTax - discountAmount;
            } else {
              return totalTax;
            }
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    GTotal() {
      return this.LineTotal + this.VatSum;
    }
  },
  data() {
    return {
      dialog: false,
      searchItem: null,
      PriceBefDi: null,
      DiscPrcnt: null,
      Quantity: null,
      TaxCode: null,
      TaxRate: null,
      Rate: null,
      Commission: null,
      ShipDate: null,
      BackOrdr: "N",
      LinePoPrss: "N",
      singleSelect: true,
      itemDialog: false,
      selectedItem: {},
      record: {},
      UomCode: null,
      itemRecord: {},
      LineStatus: null,
      itemsPerPage: 20,
      uoms: [],
      taxes: [],
      employees: [],
      selectedItems: [],
      itemMasterData: [],
      itemMasterHeaders: [
        { text: "Item Code", value: "ItemCode" },
        { text: "Item Description", value: "ItemName" },
        { text: "UOM", value: "SalUnitMsr" },
        { text: "Sales Tax group", value: "VatGourpSa" }
      ]
    };
  },
  watch: {
    initial: {
      handler: "init",
      immediate: true
    }
  },
  methods: {
    init(val) {
      this.itemRecord = { ...val };
      // quantity
      if (val.Quantity) {
        this.Quantity = val.Quantity;
      }
      // unit price
      if (val.PriceBefDi) {
        this.PriceBefDi = val.PriceBefDi;
      }
      // discount
      if (val.DiscPrcnt) {
        this.DiscPrcnt = val.DiscPrcnt;
      }
      // Tax code
      if (val.TaxCode) {
        this.TaxCode = parseInt(val.TaxCode);
      }
      // Tax code
      if (val.UomCode) {
        const UomCode = parseInt(val.UomCode);
        this.UomCode = UomCode
        // const self = this;
        // // get uoms
        //  this.$store
        //   .dispatch("get", `/uomgroup/${UomCode}`)
        //   .then(res => {
        //     console.log(res, 'res');
        //     self.uoms = res;
        //   })
        //   .catch(err => {
        //     console.log(err, "error");
        //   });
      }
      // partial delivery
      if (val.BackOrdr) {
        this.BackOrdr = val.BackOrdr;
      }
      // Allow Prmcnt
      if (val.LinePoPrss) {
        this.LinePoPrss = val.LinePoPrss;
      }
    },
    openItemModal() {
      this.selectedItems = [];
      this.itemDialog = true;
    },
    openDialog() {
      this.selectedItem = {};
      this.itemRecord = {};
      this.Quantity = null;
      this.PriceBefDi = null;
      this.DiscPrcnt = null;
      this.TaxCode = null;
      this.ShipDate = null;
      this.BackOrdr = "N";
      this.LinePoPrss = "N";
      this.Commission = null;
      this.dialog = true;
    },
    setShipDate(date) {
      this.itemRecord.ShipDate = date;
    },
    // changeTax(id) {
    //   const tax = this.taxes.find(record => {
    //     return record.id === id;
    //   });
    //   this.TaxRate = tax.rate;
    // },
    getPrices() {
      // make prices query request
      const data = {
        ItemCode: this.selectedItem.id,
        CardCode: this.selectedTenant.id,
        SUoMEntry: this.UomCode
      };
      const self = this;
      const url = "/getDefaultPrice";
      this.$store
        .dispatch("post", { url, data })
        .then(res => {
          // self.itemRecord.U_PricePerPricingUnit = res.PRICEPERPRICEUNIT;
          // self.itemRecord.U_Sqrft = res.SalesUnitPriceUnitConversion;
          self.itemRecord.NumPerMsr = res.SalesUnitINVUnitConversion;
          self.PriceBefDi = res.FINALSALESPRICE;
          // self.itemRecord.Currency = res.PRICINGCURRENCY;
        })
        .catch(err => {
          console.log(err, "err");
        });
      // end of request
    },
    clickedItem($event) {
      this.selectedItem = $event.item;
      this.itemRecord.ItemCode = $event.item.ItemCode;
      this.itemRecord.Dscription = $event.item.ItemName;
      if (this.selectedTenant.id) {
        const data = {
          ItemCode: this.selectedItem.id,
          CardCode: this.selectedTenant.id
        };
        // make prices query request
        const self = this;
        const url = "/getDefaultPrice";
        this.$store
          .dispatch("post", { url, data })
          .then(res => {
            self.itemRecord.NumPerMsr = res.SalesUnitINVUnitConversion;
            self.PriceBefDi = res.FINALSALESPRICE;
          })
          .catch(err => {
            console.log(err, "err");
          });
        // end of request

        // fetch items uoms
        const UgpEntry = this.selectedItem.UgpEntry;
        this.$store
          .dispatch("get", `/uomgroup/${UgpEntry}`)
          .then(res => {
            self.uoms = res;
          })
          .catch(err => {
            console.log(err, "error");
          });
        // end of uoms

        // assign default
        this.Quantity = 1;
        this.itemRecord.UseBaseUn = "N";
        this.itemRecord.PackQty = 1;
        this.itemRecord.WhsCode = 1;
        this.itemRecord.PickStatus = "N";
        // end of default values
        this.itemDialog = false;
      } else {
        this.selectedItems = [];
        this.itemRecord = {};
        this.itemDialog = false;
        const falseValue = false;
        this.$emit("closeModal", falseValue);
      }
    },
    addItem() {
      const sItem = this.selectedItem;
      const itemRecord = this.itemRecord;
      const self = this;
      const details = {
        ItemCode: sItem.id,
        Dscription: itemRecord.Dscription,
        CodeBars: itemRecord.CodeBars || null,
        SerialNum: itemRecord.SerialNum || null,
        Quantity: self.Quantity || null,
        DelivrdQty: itemRecord.DelivrdQty || null,
        UseBaseUn: itemRecord.UseBaseUn || null,
        PackQty: itemRecord.PackQty || null,
        PriceBefDi: self.PriceBefDi || null,
        DiscPrcnt: self.DiscPrcnt || null,
        Rate: itemRecord.Rate || null,
        Price: self.Price || null,
        TaxCode: self.TaxCode || null,
        PriceAfVAT: self.PriceAfVAT || null,
        LineTotal: self.LineTotal || null,
        WhsCode: itemRecord.WhsCode || null,
        ShipDate: itemRecord.ShipDate || null,
        SlpCode: itemRecord.SlpCode || null,
        Commission: itemRecord.Commission || null,
        TreeType: itemRecord.TreeType || "N",
        LineStatus: itemRecord.LineStatus || null,
        TargetType: itemRecord.TargetType || -1,
        TargetEntry: itemRecord.TargetEntry || null,
        BaseType: itemRecord.BaseType || -1,
        BaseRef: itemRecord.BaseRef || null,
        BaseEntry: itemRecord.BaseEntry || null,
        BaseLine: itemRecord.BaseLine || null,
        SpecPrice: itemRecord.SpecsPrice || "N",
        VatSum: self.VatSum || null,
        GrssProfit: self.GrssProfit || null,
        PoTrgNum: itemRecord.PoTrgNum || null,
        BackOrdr: self.BackOrdr || null,
        FreeTxt: itemRecord.FreeTxt || null,
        PickStatus: itemRecord.PickStatus || null,
        UomCode: self.UomCode || null,
        unitMsr: itemRecord.unitMsr || null,
        NumPerMsr: itemRecord.NumPerMsr || null,
        Text: itemRecord.Text || null,
        GTotal: self.GTotal || null,
        UgpCode: itemRecord.UgpCode || null,
        AgrNo: itemRecord.AgrNo || null,
        LinePoPrss: self.LinePoPrss || null,
        OnHand: itemRecord.OnHand || null,
        IsCommited: itemRecord.IsCommited || null,
        OnOrder: itemRecord.OnOrder || null,
        OpenQty: self.Quantity || null,
        InvQty: self.Quantity || null,
        OpenInvQty: self.Quantity || null,
        OwnerCode: null,
        TrnsCode: null,
        OrigItem: null,
        GPTtlBasPr: null,
        GrossBuyPr: null,
        OcrCode5: null,
        OcrCode4: null,
        OcrCode3: null,
        OcrCode2: null,
        OcrCode: null,
        AcctCode: null
      };

      this.$emit("itemData", details);
      // clear fields
      this.selectedItem = {};
      this.itemRecord = {};
      this.Quantity = null;
      this.PriceBefDi = null;
      this.DiscPrcnt = null;
      this.TaxCode = null;
      this.ShipDate = null;
      this.BackOrdr = "N";
      this.LinePoPrss = "N";
      this.Commission = null;
    },
    getItemMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/item_masterdata`)
        .then(res => {
          self.itemMasterData = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then(res => {
          self.employees = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTaxGroups() {
      const self = this;
      this.$store
        .dispatch("get", `/taxgroups/output`)
        .then(res => {
          self.taxes = res;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getItemMaster();
    this.getEmployees();
    this.getTaxGroups();
  }
};
</script>

<style scoped>
</style>